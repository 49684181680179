/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import get from "lodash/get";
import { StoryElement, WithLazy } from "@quintype/components";

import Tags from "../story-elements/tags";
import AlsoRead from "../story-elements/also-read";
import Slideshow from "../story-elements/slide-show";
import Attachment from "../story-elements/attachment";
import FactCheckView from "../story-elements/fact-check";
import ImageGallery from "../story-elements/image-gallery";
import SponsoredTag from "../story-elements/sponsored-tag";
import MovieReviewView from "../story-elements/movie-review";
import GadgetReviewView from "../story-elements/gadget-review";
import ReferenceElement from "../story-elements/reference-element";
import BookReviewView from "../story-elements/book-review";
import SubscribeCard from "../../molecules/subscribe-card";
import PublishedAt from "./published-at";

import SectionStories from "./section-stories";

import { AdContainer } from "../../ads";
import TaboolaAd from "../../ads/taboola-ad";

import MobileBottomWidgets from "../mobile-bottom-widgets";

import BlockQuoteIcon from "../../atoms/icons/blockquote-icon";
import QuoteIcon from "../../atoms/icons/quote-icon";
import PromotionalMessage from "./promotional-message";
import ImagewithCaption from "../../molecules/Image-caption";
import ImagewithCaptionWithoutShare from "../../molecules/image-without-share";

import { getPrimaryEmbed } from "../story-header/embed-element";

import "./story-elements.m.css";
import QandA from "./q-and-a";
import useAdType from "../../helper/useAdType";
import ElectionBrickWall from "../../molecules/election-brick-wall";
import {
  assemblyElection2024ConstituencyData,
  assemblyElection2024SlugData_Delhi
} from "../../molecules/election-brick-wall-with-search/election-data-2024";
import { useSelector } from "react-redux";

let countPara = 0;
let hasRenderedMidAd1 = false;
let hasRenderedMidAd2 = false;

const getParagraphsArr = (html) => {
  // const pTagRegex = /<p\b[^>]*>([\s\S]*?)<\/p>/g;
  // const matches = html.match(pTagRegex);
  // return matches;

  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const elements = [...doc.body.children];
  return elements.map((el) => el.outerHTML);
};

const CustomStoryElement = ({ element, elementIndex, adType, story }) => {
  const rawHtml = element && element.text;
  const [paragraphs, setParagraphs] = useState([]);
  const storyTemplate = story && story["story-template"];
  const blockedTemplates = ["listicle", "live-blog"];
  useEffect(() => {
    let isWebView = false;
    if (typeof window !== "undefined") {
      const searchparam = window.location.search;
      if (searchparam.includes("app=true")) {
        isWebView = true;
      }
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = rawHtml;
      const paragraphElements = getParagraphsArr(rawHtml);
      console.log("rawHtml", rawHtml);
      console.log("paragraphElements", paragraphElements);
      const parsedParagraphs =
        paragraphElements &&
        paragraphElements.map((p, index) => {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = p;
          // Check if the element contains a <p> tag
          const hasPTag = tempElement.getElementsByTagName("p").length > 0;
          if (hasPTag) {
            countPara = countPara + 1;
          }
          if (countPara > 0 && countPara === 4 && !hasRenderedMidAd2) {
            hasRenderedMidAd2 = true;
            return (
              <div key={index}>
                <div className="story-element story-element-text" dangerouslySetInnerHTML={{ __html: p }}></div>
                <div styleName="ad-wrapper" className="hide-desktop">
                  {!isWebView && (story["story-template"] === "text" || story["story-template"] === "syndicated") && (
                    <AdContainer
                      AdClassName="standard-336x280"
                      id="div-gpt-ad-1726651118047-0"
                      desktop={false}
                      mobile="DH_MWeb_AT_Mid02"
                    />
                  )}
                </div>
              </div>
            );
          }
          if (!blockedTemplates.includes(storyTemplate) && countPara > 0 && countPara === 1 && !hasRenderedMidAd1) {
            hasRenderedMidAd1 = true;
            return (
              <div key={index}>
                <div className="story-element story-element-text" dangerouslySetInnerHTML={{ __html: p }}></div>
                {storyTemplate !== "podcast" && (
                  <div styleName="ad-wrapper" className="hide-desktop">
                    <AdContainer
                      AdClassName="standard-336x280"
                      id={adType === "izooto" ? "div-gpt-ad-1711972030627-0" : "div-gpt-ad-1680157855094-0"}
                      mobile={adType === "izooto" ? "DH_MWeb_AT_Mid_ym" : "DH_MWeb_AT_Mid"}
                      isExternalAdPath={adType === "izooto" ? true : false}
                    />
                  </div>
                )}
                <div styleName="ad-wrapper" className="hide-mobile">
                  <AdContainer
                    AdClassName="standard-336x280"
                    id={adType === "izooto" ? "div-gpt-ad-1711971649563-0" : "div-gpt-ad-1680156704803-0"}
                    desktop={adType === "izooto" ? "DH_Desktop_AT_Mid_ym" : "DH_Desktop_AT_Mid"}
                    isExternalAdPath={adType === "izooto" ? true : false}
                  />
                </div>
              </div>
            );
          }
          return (
            <div key={index}>
              <div className="story-element story-element-text" dangerouslySetInnerHTML={{ __html: p }}></div>
            </div>
          );
        });
      setParagraphs(parsedParagraphs);
    }
  }, []);

  return <div>{paragraphs}</div>;
};
const fourthTextStoryElementID = (story) => {
  const textStoryElements = [];
  story.cards.map((element) =>
    element["story-elements"]?.map((element) => {
      if (element.subtype === null && element.type === "text") {
        textStoryElements.push(element);
      }
    })
  );
  return textStoryElements.length > 5 ? textStoryElements[3].id : null;
};

const getElements = (element, story, elementIndex, cardIndex, isImmersiveStory, adType) => {
  if (!isImmersiveStory) {
    const primaryEmbed = getPrimaryEmbed(story);
    const primaryEmbedId = primaryEmbed?.id ? primaryEmbed.id : null;
    if (
      element.id === primaryEmbedId &&
      (story["story-template"] == "embed" ||
        story["story-template"] == "video-playlist" ||
        story["story-template"] == "podcast")
    ) {
      return null;
    }
  }
  const elementType = element.type;

  if (element.metadata?.["promotional-message"] === true) {
    return null;
  }

  if (element && element.type === "text" && element.subtype === null) {
    return (
      <div id="text-element-with-ad">
        <CustomStoryElement element={element} elementIndex={elementIndex} adType={adType} story={story} />
      </div>
    );
  }

  switch (element.subtype) {
    case "attachment": {
      return <Attachment element={element} />;
    }
    case "references": {
      const referenceObj = get(element, ["story-elements"], []);
      return referenceObj.map((element, index) => <ReferenceElement element={element} key={index} />);
    }
    case "image-gallery": {
      if (element.metadata.type === "gallery") {
        return <WithLazy margin="50px">{() => <ImageGallery element={element} story={story} />}</WithLazy>;
      }

      if (element.metadata.type === "slideshow") {
        return <WithLazy margin="50px">{() => <Slideshow story={story} element={element} />}</WithLazy>;
      }
      break;
    }
    case "blockquote": {
      return (
        <div styleName="blockquote-wrapper">
          <BlockQuoteIcon width="40" height="40px" />
          <StoryElement element={element} key={element.id} story={story} />
        </div>
      );
    }
    case "also-read": {
      return <AlsoRead element={element} story={story} isImmersiveStory={isImmersiveStory} />;
    }
    case "quote": {
      return (
        <div styleName="quote-contents" className="exclusive-contents">
          <QuoteIcon />
          <StoryElement element={element} key={element.id} story={story} />
        </div>
      );
    }
    case "q-and-a": {
      return <QandA element={element} story={story} subtype={element.subtype} />;
    }
    case "question": {
      return <QandA element={element} story={story} subtype={element.subtype} />;
    }
    case "answer": {
      return <QandA element={element} story={story} subtype={element.subtype} />;
    }
    case "table": {
      if (element.metadata["has-header"] == true) {
        const updateEle = { ...element };
        updateEle.metadata["has-header"] = false;
        return (
          <div className="has-head story-element story-element-data">
            <StoryElement element={updateEle} key={updateEle.id} story={story} />;
          </div>
        );
      } else {
        return <StoryElement element={element} key={element.id} story={story} />;
      }
    }
    default:
      if (elementType === "image") {
        if (
          story["story-template"] === "photo" ||
          story["story-template"] === "text-new" ||
          story["story-template"] === "sponsored"
        ) {
          return (
            <div styleName="share-wrapper">
              <StoryElement
                renderTemplates={{ image: ImagewithCaption }}
                element={element}
                key={element.id}
                story={story}
                index={elementIndex}
              />
            </div>
          );
        } else {
          return (
            <StoryElement
              renderTemplates={{ image: ImagewithCaptionWithoutShare }}
              element={element}
              key={element.id}
              story={story}
            />
          );
        }
      } else {
        return (
          <div styleName="share-wrapper">
            {story["story-template"] !== "podcast" || story["story-template"] !== "video-playlist" ? (
              <StoryElement element={element} key={element.id} story={story} loadIframeOnClick />
            ) : elementType !== "jsembed" ? (
              <StoryElement element={element} key={element.id} story={story} loadIframeOnClick />
            ) : element.subtype === "" || element.subtype === null ? (
              console.log("element.subtype ignored:>:>:>:>:>", elementType, element.subtype)
            ) : (
              <StoryElement element={element} key={element.id} story={story} loadIframeOnClick />
            )}
          </div>
        );
      }
  }
};

export const StoryCard = ({
  story,
  card,
  cardIndex,
  index,
  isNewTextStory,
  showAlsoReadOnTop = true,
  adType,
  isMobile,
  isImmersiveStory
}) => {
  const elements = card["story-elements"];
  const wrappedAllElementCard = (elements, story, card, cardIndex, adType, isMobile) => {
    let allAlsoReads = [];
    let allWrappedElements = [];

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];

      if (element.subtype === "also-read") {
        allAlsoReads.push(element);
      } else {
        if (allAlsoReads.length > 1) {
          allWrappedElements.push(
            <div styleName="also-read-main-wrapper">
              {allAlsoReads.map((element, elementIndex) => (
                <AlsoRead
                  story={story}
                  element={element}
                  isConsecutive={true}
                  elementIndex={elementIndex}
                  isImmersiveStory={isImmersiveStory}
                />
              ))}
            </div>
          );
        } else {
          allWrappedElements.push(
            ...allAlsoReads.map((element, elementIndex) => (
              <AlsoRead
                story={story}
                element={element}
                isConsecutive={false}
                elementIndex={elementIndex}
                isImmersiveStory={isImmersiveStory}
              />
            ))
          );
        }

        allAlsoReads = [];
        allWrappedElements.push(getElements(element, story, i, cardIndex, isImmersiveStory, adType));
      }
    }
    if (allAlsoReads.length > 1) {
      allWrappedElements.push(
        <div styleName="also-read-main-wrapper">
          {allAlsoReads.map((element, elementIndex) => (
            <AlsoRead
              story={story}
              element={element}
              isConsecutive={true}
              elementIndex={elementIndex}
              isImmersiveStory={isImmersiveStory}
            />
          ))}
        </div>
      );
    } else {
      allWrappedElements.push(
        ...allAlsoReads.map((element, elementIndex) => (
          <AlsoRead
            story={story}
            element={element}
            isConsecutive={false}
            elementIndex={elementIndex}
            isImmersiveStory={isImmersiveStory}
          />
        ))
      );
    }
    return allWrappedElements;
  };
  return (
    <div
      styleName={`story-card ${isNewTextStory ? "card-border" : ""}`}
      key={card.id}
      id={card.id}
      className="story-card"
    >
      {wrappedAllElementCard(elements, story, card, cardIndex, adType, isMobile).map((element, elementIndex) => {
        const elementType = element?.props?.children?.props?.element?.type;
        return (
          <React.Fragment key={elementIndex}>
            <React.Fragment>{element}</React.Fragment>
            {fourthTextStoryElementID(story) === element?.id &&
            (story["story-template"] === "text" || story["story-template"] === "syndicated") &&
            story["story-template"] !== "immersive" ? (
              <SectionStories story={story} />
            ) : null}
            {cardIndex === 0 &&
            elementIndex === 0 &&
            elementType !== "text" &&
            story["story-template"] !== "immersive" ? (
              <>
                <div styleName="ad-wrapper" key={index}>
                  {isMobile ? (
                    <AdContainer
                      AdClassName="standard-336x280"
                      id={adType === "izooto" ? "div-gpt-ad-1711972030627-0" : "div-gpt-ad-1680156704803-0"}
                      mobile={adType === "izooto" ? "DH_MWeb_AT_Mid_ym" : "DH_MWeb_AT_Mid"}
                      isExternalAdPath={adType === "izooto" ? true : false}
                    />
                  ) : (
                    <AdContainer
                      AdClassName="standard-336x280"
                      id={adType === "izooto" ? "div-gpt-ad-1711971649563-0" : "div-gpt-ad-1680156704803-0"}
                      desktop={adType === "izooto" ? "DH_Desktop_AT_Mid_ym" : "DH_Desktop_AT_Mid"}
                      isExternalAdPath={adType === "izooto" ? true : false}
                    />
                  )}
                </div>
              </>
            ) : null}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const getUpdatedCards = (cards) => {
  const updatedCards = [];
  let alsoReadElements = [];

  for (let card = 0; card < cards.length; card++) {
    const firstCard = cards[card];
    const secondCard = cards[card + 1];
    const firstCardLastElement = firstCard["story-elements"][firstCard["story-elements"].length - 1];
    const lastCard = cards[cards.length - 1];

    if (firstCardLastElement?.subtype === "also-read" && secondCard) {
      for (let element = 0; element < secondCard["story-elements"].length; element++) {
        if (secondCard["story-elements"][element]?.subtype === "also-read") {
          alsoReadElements.push(secondCard["story-elements"][element]);
        } else {
          break;
        }
      }
      firstCard["story-elements"] = [...firstCard["story-elements"], ...alsoReadElements];
      updatedCards.push(firstCard);
      const updatedsecondCardElements = secondCard["story-elements"].filter(
        (element) => !alsoReadElements.includes(element)
      );
      secondCard["story-elements"] = updatedsecondCardElements;
      if (secondCard.id === lastCard.id) {
        updatedCards.push(secondCard);
      }
    } else {
      if (!updatedCards.includes(firstCard)) {
        updatedCards.push(firstCard);
      }
    }
    alsoReadElements = [];
  }

  return updatedCards;
};

const StoryElements = ({
  story,
  index,
  cards,
  isFactCheck,
  isMovieReview,
  isGadgetReview,
  isBookReview,
  isSponsoredStory,
  isImmersiveStory,
  isNewTextStory,
  fontSize
}) => {
  const template = story["story-template"] ? story["story-template"] : "text";
  const [isMobile, setMobile] = useState(false);
  const adType = useAdType();
  const mainRef = useRef(null);
  const showBrickWall = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "show_brickWall"], false)
  );
  useEffect(() => {
    if (mainRef?.current && isImmersiveStory && fontSize) {
      mainRef?.current.style.setProperty("--storyContentFont", fontSize + "px");
      mainRef?.current.style.setProperty("--base", fontSize + "px");
    }
  }, []);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const updateCards = getUpdatedCards(cards);

  const isExclusiveStory = story["story-template"] === "dh-exclusive";

  const showBrickWallStoryPage = story?.slug.includes("elections/delhi");
  return (
    <div styleName={`wrapper ${isImmersiveStory ? "remove-margin" : ""}`} ref={mainRef}>
      {template === "movie-review" && <MovieReviewView story={story} />}
      {template === "gadget-review" && <GadgetReviewView story={story} />}
      {template === "book-review" && <BookReviewView story={story} />}
      <div id={`story-content-${story.id}`} className="story-cards taboola-read-more-container">
        {updateCards.map((card, cardIndex) => (
          <StoryCard
            card={card}
            story={story}
            key={card.id}
            cardIndex={cardIndex}
            index={index}
            isNewTextStory={isNewTextStory}
            adType={adType}
            isMobile={isMobile}
            isImmersiveStory={isImmersiveStory}
          />
        ))}
        {!isImmersiveStory && (
          <>
            {fourthTextStoryElementID(story) === null &&
            (story["story-template"] === "text" || story["story-template"] === "syndicated") ? (
              <SectionStories story={story} />
            ) : null}
            {isFactCheck && <FactCheckView story={story} />}
            {isSponsoredStory && <SponsoredTag />}
            <div styleName="ad-wrapper" className="hide-desktop">
              <AdContainer
                AdClassName="standard-336x280"
                id={adType === "izooto" ? "div-gpt-ad-1711972114719-0" : "div-gpt-ad-1680157888251-0"}
                mobile={adType === "izooto" ? "DH_MWeb_AT_Bottom_ym" : "DH_MWeb_AT_Bottom"}
                isExternalAdPath={adType === "izooto" ? true : false}
              />
            </div>
            {showBrickWall && showBrickWallStoryPage && (
              <ElectionBrickWall
                stateName="DELHI"
                showStateTabs={false}
                electionSlugData={assemblyElection2024SlugData_Delhi}
                electionData={assemblyElection2024ConstituencyData}
                year={"2025"}
                showSeperator={false}
              />
            )}
            {!isExclusiveStory && <PublishedAt story={story} />}
            <Tags story={story} />
            <PromotionalMessage story={story} />
            <SubscribeCard story={story} />
            {isMobile ? (
              <div className="side-story-section hide-desktop">
                <MobileBottomWidgets index={index} story={story} />
              </div>
            ) : null}
          </>
        )}
      </div>

      {!isImmersiveStory && (
        <>
          <TaboolaAd
            container={`'taboola-below-article-${story.id}`}
            placement={`Below ${
              template === "video" ? "Video" : template === "photo" ? "Photo" : "Article"
            } Thumbnails`}
            mode="thumbnails-b"
          />
        </>
      )}
    </div>
  );
};

StoryElements.defaultProps = {
  isFactCheck: false,
  isMovieReview: false,
  isGadgetReview: false,
  isSponsoredStory: false,
  isImmersiveStory: false,
  isNewTextStory: false,
  isBookReview: false
};

export default StoryElements;
